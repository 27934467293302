import { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch, useSelector } from 'react-redux';
import { toggle_modal } from 'redux/actions/modal';
import { post_policy, put_data } from 'redux/actions/main';
import useScrollbar from 'helper/hooks/useScrollbar';

const Policy = (props) => {
  const { user } = useSelector((state) => state.main);
  const handleClickBtn = (status) => {
    dispatch(put_data('accept_policy', true));
    if (user) {
      dispatch(post_policy(status));
    } else {
      dispatch(toggle_modal('policy', false));
    }
  };

  const dispatch = useDispatch();
  const { hasScrollBar, isEndScroll, setIsEndScroll, checkScrollBar, handleScroll } = useScrollbar();
  const { policy } = props.modal;
  const { tr } = props.main;

  useEffect(() => {
    if (policy) {
      setIsEndScroll(false);
      checkScrollBar('#policy');
    }

    // eslint-disable-next-line
  }, [policy]);

  return (
    <Modal
      open={policy}
      onClose={() => dispatch(toggle_modal('policy', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: `modal policy animated fast ${policy ? 'slideInUp' : 'slideOutDown'}`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'slideOutDown',
      }}>
      <div className="title center">{tr.txt_policy_title}</div>
      <div className="content">
        <div
          id="policy"
          className="event-info"
          dangerouslySetInnerHTML={{ __html: tr.txt_policy_content }}
          onScroll={handleScroll}
        />
        <div className="btn-wrapper">
          <button onClick={() => handleClickBtn(0)}>{tr.txt_policy_reject}</button>
          <button onClick={() => handleClickBtn(1)}>{tr.txt_policy_accept}</button>
        </div>
      </div>
      {hasScrollBar && <div className={`arrow ${isEndScroll ? 'hidden' : ''}`} />}
    </Modal>
  );
};

export default Policy;
