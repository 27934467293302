import { useDispatch } from 'react-redux';
import { set_msg, toggle_modal } from 'redux/actions/modal';

const ButtonClose = ({ type }) => {
  const dispatch = useDispatch();

  return (
    <button
      className="btn-close"
      onClick={() => {
        dispatch(toggle_modal(type, false));
        dispatch(set_msg('error', false));
      }}
    />
  );
};

export default ButtonClose;
