import axios from "axios"

import { getAllUrlParam, removeParamUrl } from "helper"
import { catch_error, check_login, get_leaderboard, get_static_info } from "./main"

const ls = window.localStorage

export const set_param = (data) => ({
  type: "SET_PARAM",
  data,
})

export const set_token = () => {
  return async (dispatch, getState) => {
    const { auth } = getState();

    const params = getAllUrlParam()
    let param = Object.keys(params).length === 0 ? auth?.param : params

    if (param?.err) {
      if (param.err === '20') {
        window.location.href =
          process.env.REACT_APP_API_ENDPOINT + 'oauth/login/';
      } else {
        dispatch(catch_error(param.err));
        return
      }
    }

    try {
      if (param?.access_token || param?.gop_access_token) {
        await dispatch(getJWT(param))
        dispatch(set_param(param))
      }
      dispatch(check_login())
      dispatch(get_static_info())
      dispatch(get_leaderboard())
      removeParamUrl()
    } catch (error) {
      dispatch(catch_error(error))
    }
  }
}

export const getJWT = (param) => {
  return () =>
    new Promise(async (resolve, resject) => {
      try {
        const resp = await axios.get(`oauth/callback/`, { params: param })
        ls.setItem("token", resp.data.token)
        axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] =
          resp.data.token
        resolve()
      } catch (error) {
        const err = error?.response?.data?.message
        resject(err)
      }
    })
}
