import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  get_translate,
  hit,
} from 'redux/actions/main';
import { set_token } from 'redux/actions/auth';

// Component
import ErrorBoundary from 'ErrorBoundary';
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
import LoadingScreen from 'helper/component/loadingscreen';
import Modal from 'components/Modal';

import { checkLang, componentLoader, fixView } from 'helper';

// Pages
const Home = lazy(() => componentLoader(() => import('pages/Home')));

const Main = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [innerHeight, setInnerHeight] = useState(0);

  const dispatch = useDispatch();
  const { loader, local_score, initial_score } = useSelector(
    (state) => state.main
  );

  useEffect(() => {
    fixView();
    dispatch(set_token());
    dispatch(get_translate(checkLang()));

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    let interval;
    const perHitLimit = 1000;

    if (isVisible) {
      const hitCount = local_score - initial_score;
      const time = hitCount > 0 ? 2000 : 10000;
      
      interval = setInterval(() => {
        if (hitCount > 0) {
          const sendedHitCount = hitCount < perHitLimit ? hitCount : perHitLimit;
          dispatch(hit(sendedHitCount, hitCount));
        }
      }, time);
    }
    return () => clearInterval(interval);
  }, [dispatch, isVisible, local_score, initial_score]);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
    const updateWindowDimensions = () => {
      setInnerHeight(window.innerHeight);
    };
    
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 
  }, [])

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div id="Main" className="main-panel">
        <ErrorBoundary>
          <Suspense fallback={<LoadingScreen />}>
            <ViewportRestrict
              display={false}
              type="landscape"
              text="PLEASE ROTATE YOUR PHONE FOR THE BEST BROWSING EXPERIENCE"
            />
            <Loader loader={loader} />
            <Modal />
            <div className="content-container" style={{ height: innerHeight + 'px' }}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </Router>
  );
};

export default Main;
