import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { toggle_modal } from 'redux/actions/modal';

import ButtonClose from 'components/ButtonClose';
import { put_data } from 'redux/actions/main';

const Login = (props) => {
  const handleClickLogin = (type) => {
    dispatch(put_data('from_outgame', true));
    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}${
      type === 'fb' ? 'oauth/login/fb' : 'oauth/login'
    }`;
  };

  const dispatch = useDispatch();
  const { login } = props.modal;
  const { tr } = props.main;

  return (
    <Modal
      open={login}
      onClose={() => dispatch(toggle_modal('login', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: `modal login animated fast ${
          login ? 'slideInUp' : 'slideOutDown'
        }`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'slideOutDown',
      }}
    >
      <div className="title orange">
        {tr.txt_title_login}
        <ButtonClose type="login" />
      </div>

      <div className="content">
        {/* <div className="icon" /> */}
        <p>{tr.txt_login_with}</p>
        <div className="btn-wrapper">
          {/* <button className="fb" onClick={() => handleClickLogin('fb')} /> */}
          <button
            className="garena"
            onClick={() => handleClickLogin('garena')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Login;
