import CryptoJS from 'crypto-js';
import { preload_data } from './data-preload';

export const ls = window.localStorage;

export const CDN =
  'https://cdngarenanow-a.akamaihd.net/gstaticid/Undawn/zombieslap/';

export const setCssVariable = (assets) => {
  const setVariable = (varName, url) => {
    const root = document.querySelector(':root');
    root.style.setProperty(varName, `url(${url})`);
  };

  setVariable('--first-icon', assets?.img_1st_icon);
  setVariable('--second-icon', assets?.img_2nd_icon);
  setVariable('--third-icon', assets?.img_3rd_icon);
  setVariable('--bg', assets?.img_bg);
  setVariable('--bg-desktop', assets?.img_bg_desktop);
  setVariable('--bg-leaderboard', assets?.img_bg_leaderboard);
  setVariable('--bg-modal', assets?.img_bg_modal);
  setVariable('--bg-modal-big', assets?.img_bg_modal_big);
  setVariable('--bg-tutorial', assets?.img_bg_tutorial);
  setVariable('--btn-login', assets?.img_btn_login);
  setVariable('--btn-rule', assets?.img_btn_rule);
  setVariable('--gun', assets?.img_gun);
  setVariable('--gun-desktop', assets?.img_gun_desktop);
  setVariable('--gun-effect', assets?.img_gun_effect);
  setVariable('--gun-hit', assets?.img_gun_hit);
  setVariable('--gun-hit-desktop', assets?.img_gun_hit_desktop);
  setVariable('--ico-gun-off', assets?.img_ico_gun_off);
  setVariable('--ico-gun-on', assets?.img_ico_gun_on);
  setVariable('--ico-knife-off', assets?.img_ico_knife_off);
  setVariable('--ico-knife-on', assets?.img_ico_knife_on);
  setVariable('--ico-palm-off', assets?.img_ico_palm_off);
  setVariable('--ico-palm-on', assets?.img_ico_palm_on);
  setVariable('--ico-sound-off', assets?.img_ico_sound_off);
  setVariable('--ico-sound-on', assets?.img_ico_sound_on);
  setVariable('--icon-close', assets?.img_icon_close);
  setVariable('--icon-share', assets?.img_icon_share);
  setVariable('--knife', assets?.img_knife);
  setVariable('--knife-desktop', assets?.img_knife_desktop);
  setVariable('--knife-effect', assets?.img_knife_effect);
  setVariable('--knife-hit', assets?.img_knife_hit);
  setVariable('--knife-hit-desktop', assets?.img_knife_hit_desktop);
  setVariable('--logo', assets?.img_logo);
  setVariable('--orange-bar', assets?.img_orange_bar);
  setVariable('--ranking-bar', assets?.img_ranking_bar);
  setVariable('--reward-bar', assets?.img_reward_bar);
  setVariable('--reward-square-grey', assets?.img_reward_square_grey);
  setVariable('--reward-square-orange', assets?.img_reward_square_orange);
  setVariable('--reward-square-red', assets?.img_reward_square_red);
  setVariable('--slap', assets?.img_slap);
  setVariable('--slap-desktop', assets?.img_slap_desktop);
  setVariable('--slap-effect', assets?.img_slap_effect);
  setVariable('--slap-hit', assets?.img_slap_hit);
  setVariable('--slap-hit-desktop', assets?.img_slap_hit_desktop);
  setVariable('--title-event', assets?.img_title_event);
  setVariable('--zombie', assets?.img_zombie);
  setVariable('--zombie-hit', assets?.img_zombie_hit);
  setVariable('--btn-logout', assets?.img_btn_logout);
  setVariable('--bg-score', assets?.img_bg_score);
  setVariable('--grey-base', assets?.img_grey_base);
  setVariable('--header-bg', assets?.img_header_bg);
  setVariable('--btn-prereg', assets?.img_btn_prereg);
  setVariable('--crosshair', assets?.img_crosshair);
  setVariable('--side-tab', assets?.img_side_tab);
  setVariable('--side-tab-open', assets?.img_side_tab_open);
  setVariable('--square-frame', assets?.img_square_frame);
  setVariable('--btn-main', assets?.img_btn_main);
  setVariable('--bg-rewards', assets?.img_bg_rewards);
  setVariable('--modal-header', assets?.img_modal_header);
  setVariable('--ico-medal', assets?.img_ico_medal);
  
  preload([
    assets?.img_bg_modal,
    assets?.img_zombie_hit,
    assets?.img_gun_hit,
    assets?.img_gun_hit_desktop,
    assets?.img_knife_hit,
    assets?.img_knife_hit_desktop,
    assets?.img_slap_hit,
    assets?.img_slap_hit_desktop,
    assets?.img_gun_effect,
    assets?.img_knife_effect,
    assets?.img_slap_effect,
    assets?.sounds_slap,
    assets?.sounds_knife,
    assets?.sounds_gun,
  ])
};

export const checkLang = () => {
  const { host } = window.location;
  if (String(host).indexOf('id') !== -1) return 'id'
  if (String(host).indexOf('th') !== -1) return 'th'
  if (String(host).indexOf('com') !== -1) return 'ms'
  if (String(host).indexOf('ph') !== -1) return 'ph'
  if (String(host).indexOf('tw') !== -1) return 'tw'
  return 'ms'
};

export const timeout = (fn, delay) => {
  let timer = null;
  timer = setTimeout(() => {
    fn();
    clearTimeout(timer);
    timer = null;
  }, delay);
};

export const formatNumber = (num) => {
  if (!num) {
    return 0;
  }

  const numArr = num.toString().split('.');
  let integer = numArr[0];
  let decimal = numArr.length > 1 ? `.${numArr[1]}` : '';
  const reg = /(\d+)(\d{3})/;
  while (reg.test(integer)) {
    integer = integer.replace(reg, '$1,$2');
  }
  return `${integer}${decimal}`;
};

export const toOrdinalSuffix = (num) => {
  if (!num) {
    return '1ST';
  }

  const int = parseInt(num),
    digits = [int % 10, int % 100],
    ordinals = ['st', 'nd', 'rd', 'th'],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3];
};

export const removeParamUrl = () => {
  const location = window.location;
  window.history.pushState(
    {},
    document.title,
    location.origin + location.pathname
  );
};

/**
 * Get all param url
 */
export const getAllUrlParam = (url = window.location) => {
  let params = {}

  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]]
      }
      params[key].push(val)
    } else {
      params[key] = val
    }
  })

  return params
}

export const getUrlParam = (pa) => {
  let url = window.location.href.replace(/#+.*$/),
    params = url.substring(url.indexOf('?') + 1, url.length).split('&'),
    param = {};

  for (let i = 0; i < params.length; i++) {
    let pos = params[i].indexOf('='),
      key = params[i].substring(0, pos),
      val = params[i].substring(pos + 1);

    param[key] = val;
  }

  return typeof param[pa] === 'undefined' ? false : param[pa];
};

/**
 *
 * @param {object} err - Error response object from axios.
 */
export const err_msg = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        return 'Please Refresh Your Browser!';
      default:
        return err.response.data.message;
    }
  } else if (err.request) {
    return 'Please Refresh Your Browser!';
  } else {
    return 'Please Refresh Your Browser!';
  }
};

const self = {};

export const fixView = () => {
  function fix() {
    let h = window.innerHeight;
    let viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute(
      'content',
      'height=' +
        h +
        ', width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no'
    );
  }
  fix();
  self.windowWidth = window.innerWidth;
  self.windowHeight = window.innerHeight;
  window.addEventListener('resize', function () {
    // Both width and height changed (orientation change)
    // self is a hack, as Android when eyboard pops up
    // Triggers orientation change
    if (
      self.windowWidth !== window.innerWidth &&
      self.windowHeight !== window.innerHeight
    ) {
      self.windowWidth = window.innerWidth;
      self.windowHeight = window.innerHeight;
      fix();
    }
  });
};

/**
 *
 * @param {string} key - Unique key for storing and identifying your data in local storage.
 * @param {*} values - Value of data you want store in local storage.
 */
export const setLsObject = (key, values) => {
  window.localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

/**
 *
 * @param {string} key - Unique key for storing your hash data in local storage.
 * @param {*} values - Value of data from hash endpoint you want store in local storage.
 */
export const setHash = (key, values) => {
  setLsObject('hash', {
    ...getLsObject('hash'),
    [key]: values,
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your hash data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getHash = (key) => {
  return getLsObject('hash')?.[key];
};

/**
 *
 * @param {string} key - Uniue key for storing your cached data in local storage.
 * @param {*} values - Value of data from any endpoint you want to be cached.
 */
export const setCache = (key, values) => {
  setLsObject('cache', {
    ...getLsObject('cache'),
    [key]: {
      ...getLsObject('cache')[key],
      data: values,
    },
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your cached data in local storage.
 * @returns {*} - Return any type of data you cached.
 */
export const getCache = (key) => {
  return getLsObject('cache')?.[key]?.data;
};

/**
 *
 * @param {string} key - Unique key for storing your PDC data in local storage.
 * @param {*} value - Value of data from PDC endpoint you want to store.
 */
export const setPdc = (key, value) => {
  setLsObject('pdc', {
    ...getLsObject('pdc'),
    [key]: value,
  });
};

/**
 *
 * @param {string} key - Unique key for accessing your PDC data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getPdc = (key) => {
  return getLsObject('pdc')?.[key]?.value;
};

/**
 *
 * @param {string} key - Reset cached data.
 */
export const resetThrottle = (key) => {
  setLsObject('cache', {
    ...getLsObject('cache'),
    [key]: {},
  });
};

/**
 *
 * @param {string} key - Unique key for identifying your data on cache.
 * @param {number} ms - Number of miliseconds that data you want to throttle.
 */
export const throttle = (key, ms) => {
  const now = new Date();
  const item = getLsObject('cache');
  const lastTime = item?.[key]?.time || 0;

  const timeDiff = now.getTime() - lastTime;

  if (timeDiff < ms) {
    return true;
  } else {
    setLsObject('cache', {
      ...getLsObject('cache'),
      [key]: {
        ...getLsObject('cache')?.[key],
        time: now.getTime(),
      },
    });

    return false;
  }
};

/**
 *
 * @param {*} payload - Payload that you want to encrypt
 * @param {string} type - Type of the payload.
 */
export const encryptPayload = (payload, type) => {
  let passphrase = '';

  if (type === 'standalone') {
    passphrase = 'WTXkexHGcqEnTRmjGHA5';
  } else if ((type = 'dtl')) {
    passphrase = 'PUTkexHGcqEnRmjAPS95';
  }

  const jsonPayload = JSON.stringify(payload);

  const ctObj = CryptoJS.AES.encrypt(jsonPayload, passphrase);
  const ctStr = ctObj.toString();

  return ctStr;
};

/**
 *
 * @param {object,array} data - list data that you want to preload
 * @param {string} prefix - prefix of image key from directus (e.g. img for img_bg)
 */
export const preload = (data = preload_data, prefix = false) => {
  // prettier-ignore
  const allowedExtensions = [ "jpg", "jpeg", "png", "mp3", "mp4", "webm", "mpg", "mpeg", "mov", "ico", "gif", "svg", "bmp", "otf", "ttf" ];
  let mapping = prefix
    ? Object.fromEntries(
        Object.entries(data).filter(([key]) => key.includes(prefix))
      )
    : data;

  let audios = [];
  let images = [];

  for (let i in mapping) {
    if (mapping.hasOwnProperty(i)) {
      let fileExtension = mapping[i].split('.').pop();

      if (allowedExtensions.includes(fileExtension)) {
        if (['mp3'].includes(fileExtension)) {
          audios[i] = new Audio();
          audios[i].src = mapping[i];
        } else {
          images[i] = new Image();
          images[i].src = mapping[i];
        }
      }
    }
  }
};

/**
 *
 * @param {component} lazyComponent - React component that you want to code splitting
 * @param {number} attemptsLeft - How many attemps to load the chunks if failed
 */
export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
};

/**
 * [EXPERIMENTAL] Send user device and other information using 3 finger touch on attached element
 *
 * @param {object} e - Event object
 */
export const sendDebug = (e) => {
  const axios = require('axios');

  const report_data = {
    embeds: [
      {
        fields: [
          {
            name: 'Website Title:',
            value: document.title,
          },
          {
            name: 'URL:',
            value: window.location.href,
          },
          {
            name: 'Release:',
            value: window.releases,
          },
          {
            name: 'User Agent:',
            value: navigator.userAgent,
          },
          {
            name: 'Viewport:',
            value: window.innerWidth + ' x ' + window.innerHeight,
          },
          {
            name: 'Token / Param:',
            value:
              window.localStorage.getItem('token') || // OTHER GAMES
              JSON.stringify(window.localStorage.getItem('param')) || // FREEFIRE
              '-',
          },
        ],
        footer: {
          text: 'React Debug Trigger by Wahyu Henditya',
        },
      },
    ],
  };

  if (e.targetTouches.length === 3) {
    axios
      .post(
        'https://discord.com/api/webhooks/839568658196070450/-8v3BOE3pnSyzgtKlHI9moC-M67dw_h1TKWgym9RLvBmUDCzJTvGVifPQNQkeSf8rZuQ',
        report_data,
        {
          transformRequest: [
            function (data, headers) {
              headers['post']['Content-Type'] = 'application/json;UTF-8';
              if (process.env.REACT_APP_USE_TOKEN === 'true') {
                delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
              }
              return JSON.stringify(data);
            },
          ],
        }
      )
      .then(() => alert('Debug Information Sent!'))
      .catch((err) => alert('Debug Information Failed to sent!'));
  }
};
