const CDN = 'https://cdngarenanow-a.akamaihd.net/gstaticid/Undawn/zombieslap/';

export const preload_data = [
  CDN + 'bg_modal.png',
  CDN + 'zombie_hit.png',
  CDN + 'slap_hit.png',
  CDN + 'slap_hit_desktop.png',
  CDN + 'knife_hit.png',
  CDN + 'knife_hit_desktop.png',
  CDN + 'gun_hit.png',
  CDN + 'gun_hit_desktop.png',
  CDN + 'slap_effect.png',
  CDN + 'knife_effect.png',
  CDN + 'gun_effect.png',

  CDN + 'sounds/slap.mp3',
  CDN + 'sounds/knife.mp3',
  CDN + 'sounds/gun.mp3',
];
