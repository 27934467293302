import ReactGA from "react-ga4";
import axios from "axios";
import { checkLang } from "helper";

export const sendWebVitals = ({ id, name, value }) => {
	ReactGA.event(
		{
			category: "Web Vitals",
			action: name,
			value: Math.round(name === "CLS" ? value * 1000 : value),
			label: id,
			nonInteraction: true
		},
		["website"]
	);
};

export const setAnalytics = () => {
	const lang = checkLang().toUpperCase()
	const GA_ID = process.env[`REACT_APP_GA_${lang}`] ?? process.env.REACT_APP_GA

	ReactGA.initialize([
		{
			trackingId: GA_ID,
			gaOptions: {
				name: "website"
			}
		},
		{
			trackingId: process.env.REACT_APP_GA_ERROR_REPORT,
			gaOptions: {
				name: "error_report"
			}
		}
	]);
	ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
	ReactGA.event({
		category: window.location.origin + window.location.pathname,
		action: window.releases
	},["website"]);
};

export const trackEventGA = (category, action, label) => {
	ReactGA.event({
		category,
		action,
		label
	}, ["website"])
}

export const setAxios = () => {
	// SET DEFAULT AXIOS
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

	if (process.env.REACT_APP_USE_TOKEN === "true") {
		let token = window.localStorage.getItem("token");
		axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
	}

	// SET AXIOS INTERCEPTOR
	axios.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error

			const config = {
				method: error?.response?.config?.method,
				status_code: error?.response?.status,
				endpoint: error?.response?.request?.responseURL
			};

			const { method, status_code, endpoint } = config;

			// Run only on production
			if (process.env.NODE_ENV === "production") {
				// Check status code, if below 5xx, don't send. Send report only 5xx error
				if (status_code > 499) {
					console.log("SEND ERROR REPORTING");
					// Will send data like 'www.FE.com -> https://www.BE.com/api/error/
					ReactGA.event(
						{
							category: `${window.location.hostname} -> ${endpoint}`,
							action: method,
							label: status_code.toString(),
							nonInteraction: true
						},
						["error_report"]
					);
				}
			}

			return Promise.reject(error);
		}
	);
};
