import { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { toggle_modal } from 'redux/actions/modal';

import ButtonClose from 'components/ButtonClose';
import useScrollbar from 'helper/hooks/useScrollbar';

const Info = (props) => {
  const dispatch = useDispatch();
  const { hasScrollBar, isEndScroll, setIsEndScroll, checkScrollBar, handleScroll } = useScrollbar();
  const { info } = props.modal;
  const { tr } = props.main;

  useEffect(() => {
    if (info) {
      setIsEndScroll(false);
      checkScrollBar('#info');
    }

    // eslint-disable-next-line
  }, [info]);

  return (
    <Modal
      open={info}
      onClose={() => dispatch(toggle_modal('info', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: `modal info animated fast ${info ? 'slideInUp' : 'slideOutDown'}`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'slideOutDown',
      }}>
      <div className="title center">
        {tr.txt_rules_title}
        <ButtonClose type="info" />
      </div>
      <div className="content">
        <div
          id="info"
          className="event-info"
          onScroll={handleScroll}
          dangerouslySetInnerHTML={{ __html: tr.txt_rules_content }}
        />
      </div>
      {hasScrollBar && <div className={`arrow ${isEndScroll ? 'hidden' : ''}`} />}
    </Modal>
  );
};

export default Info;
