import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { toggle_modal } from 'redux/actions/modal';

import ButtonClose from 'components/ButtonClose';

const Error = (props) => {
  const dispatch = useDispatch();
  const { error, msg } = props.modal;
  const { tr } = props.main;

  const errCode = {
    1: 'Missing Undawn profile',
    2: 'Error GOP',
    3: 'You dont have an Undawn account',
    4: 'Missing Undawn profile',
    41: 'Login failed, please exit the app and login again',
    5: 'Login failed, please exit the app and login again',
    m: 'Maintenance',
    not_logged_in: 'Not logged in',
    invalid_region: 'Invalid Region',
  };

  const uncloseableError = {
    "Please Refresh Your Browser!": 1,
    "server_err": 1,
    "external_api_err": 1,
    "event_maintenance": 1,
    "event_not_started": 1,
    "event_closed": 1,
    "event_restricted": 1
  }

  return (
    <Modal
      open={error}
      onClose={() => dispatch(toggle_modal('error', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: `modal error animated fast ${error ? 'slideInUp' : 'slideOutDown'}`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'slideOutDown',
      }}>
      <div className="title center">
        {tr.txt_error} {!errCode[msg.message] && !uncloseableError[msg.message ?? msg] && <ButtonClose type="error" />}
      </div>
      <div className="content">{errCode[msg.message] ?? tr[msg.message] ?? msg.message ?? "Please Refresh Your Browser!" }</div>
    </Modal>
  );
};

export default Error;
