import axios from 'axios';
import {
  checkLang,
  err_msg,
  setCssVariable,
} from 'helper';
import { set_msg, toggle_modal } from './modal';

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
});

const common = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
  withCredentials: false,
})

export const toggle_loader = (data) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const put_data = (key, data) => ({
  type: 'PUT_DATA',
  key,
  data,
});

export const update_leaderboard = (region, score) => ({
  type: 'UPDATE_LEADERBOARD',
  region,
  score
});

export const get_translate = (language) => {
  document.body.setAttribute('data-lang', language);

  return (dispatch) => {
    dispatch(put_data('lang', language));
    const commongLang = language === "ms" || language === "ph" ? "en" : language
    const main_localize = cdn.get(`${String(language).toUpperCase()}.json?${Math.random() * 10000}`)
    const common_localize = common.get(`${String(commongLang).toUpperCase()}.json?${Math.floor(Math.random() * 10000)}`)

    axios.all([ main_localize, common_localize ])
      .then((resp) => {
        const json = {
          ...resp[0].data,
          ...resp[1].data,
        }
        dispatch(put_data('tr', json));
        setCssVariable(json);
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const check_login = () => {
  return (dispatch, getState) => {
    const { accept_policy, local_score } = getState().main;

    dispatch(toggle_loader(true));

    axios
      .get('/api/user_info/')
      .then((resp) => {
        dispatch(put_data('user', resp.data));
        const score = resp.data.score ?? 0
        if (!score) {
          dispatch(sync())
        }
        dispatch(put_data('local_score',  local_score > score ? local_score : score));
        dispatch(put_data('initial_score', !score ? local_score : score));

        if (!resp.data.th_policy_checked) {
          if (checkLang() === 'th') {
            !accept_policy ? dispatch(toggle_modal('policy', true)) : dispatch(post_policy(1))
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === "not_logged_in") return
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      })
      .finally(() => {
        const { user } = getState().main;
        
        if (local_score >= 1000 && !user) {
          dispatch(toggle_modal('login', true))
        }
        if (checkLang() === 'th' && !accept_policy) {
          dispatch(toggle_modal('policy', true));
        }
      });
  };
};

export const get_static_info = () => {
  return (dispatch) => {
    axios
      .get('/api/static_info/')
      .then((resp) => {
        dispatch(put_data('rewards', resp.data.rewards));
        dispatch(put_data('servers', resp.data.servers));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const get_leaderboard = () => {
  return (dispatch) => {
    axios
      .get('/api/leaderboard/')
      .then((resp) => {
        dispatch(put_data('leaderboards', resp.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const post_policy = (action) => {
  const payload = { action };

  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .post('api/policy_check/', payload)
      .then((resp) => {
        // dispatch(check_login());
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_modal('policy', false));
        dispatch(toggle_loader(false));
      });
  };
};

export const hit = (hit_count, totalHitCount) => {
  return (dispatch, getState) => {
    const { unsended_score, initial_score } = getState().main;

    if (totalHitCount === unsended_score) return
    dispatch(put_data('unsended_score', totalHitCount))
    
    axios
      .post('api/hit/', { hit_count })
      .then((resp) => {
          dispatch(put_data('initial_score', initial_score + hit_count));
          // dispatch(get_leaderboard());
        })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const sync = () => {
  return (dispatch, getState) => {
    const { local_score } = getState().main;
    
    axios
      .post('api/sync/', { hit_count: local_score })
      .then((resp) => {
          // dispatch(check_login());
        })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const catch_error = (err_msg) => {
  return (dispatch) => {
    if (err_msg) {
      dispatch(set_msg('error', err_msg));
    }

    if (err_msg !== 'not_logged_in') {
      dispatch(toggle_modal('error', true));
    }

    dispatch(toggle_loader(false));
  };
};
