import { useState } from 'react';

const useScrollbar = () => {
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [isEndScroll, setIsEndScroll] = useState(false);

  const checkScrollBar = (elementId) => {
    setTimeout(() => {
      const ele = document.querySelector(elementId);
      const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
      setHasScrollBar(hasScrollableContent);
    }, 250);
  };

  const handleScroll = (e) => {
    const endScroll = e.target.scrollHeight - e.target.scrollTop - 10 < e.target.clientHeight;
    setIsEndScroll(endScroll ? true : false);
  };

  return {
    hasScrollBar,
    isEndScroll,
    setHasScrollBar: (bool) => setHasScrollBar(bool),
    setIsEndScroll: (bool) => setIsEndScroll(bool),
    checkScrollBar: (elementId) => checkScrollBar(elementId),
    handleScroll: (e) => handleScroll(e),
  };
};

export default useScrollbar;
