import React from 'react';

import { useSelector } from 'react-redux';

import Error from './Modal.Error';
import Login from './Modal.Login';
import Policy from './Modal.Policy';
import Info from './Modal.Info';
import Reward from './Modal.Reward';
import Share from './Modal.Share';

const Modal = () => {
  const state = useSelector((state) => state);

  return (
    <>
      <Error {...state} />
      <Login {...state} />
      <Policy {...state} />
      <Info {...state} />
      <Reward {...state} />
      <Share {...state} />
    </>
  );
};

export default Modal;
