import { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { toggle_modal } from 'redux/actions/modal';

import { formatNumber } from 'helper';

import ButtonClose from 'components/ButtonClose';
import useScrollbar from 'helper/hooks/useScrollbar';

const Reward = (props) => {

  const dispatch = useDispatch();
  const {
    hasScrollBar,
    isEndScroll,
    setIsEndScroll,
    checkScrollBar,
    handleScroll,
  } = useScrollbar();
  const { reward } = props.modal;
  const { tr, rewards } = props.main;

  const rewardBg = (idx) => {
    if (idx % 3 === 1) return 'red';
    if (idx % 3 === 2) return 'orange';
    return ''
  }

  useEffect(() => {
    if (reward) {
      setIsEndScroll(false);
      checkScrollBar('#reward');
    }

    // eslint-disable-next-line
  }, [reward]);

  return (
    <Modal
      open={reward}
      onClose={() => dispatch(toggle_modal('reward', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: `modal reward animated fast ${
          reward ? 'slideInUp' : 'slideOutDown'
        }`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'slideOutDown',
      }}
    >
      <div className="title center">
        {tr.txt_rewards_title}
        <ButtonClose type="reward" />
      </div>
      <div id="reward" className="content" onScroll={handleScroll}>
        {rewards.map((data, i) => {
          const items = [...data.items].reverse()
          return (
            <div key={i} className="list">
              <div className="milestone">
                <span>{formatNumber(data.clicks ?? 0)}</span>
                <span className="clicks">{tr.txt_clicks}</span>
              </div>
              <div className="prizes">
                {items.map((item, x) => (
                  <div key={x} className="prize">
                    <div className={`icon ${rewardBg(x)}`}>
                      <img src={item.item_img} alt="" />
                    </div>
                    <span>{formatNumber(item.item_amount)}</span>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
      {hasScrollBar && (
        <div className={`arrow ${isEndScroll ? 'hidden' : ''}`} />
      )}
    </Modal>
  );
};

export default Reward;
