import { useState, useRef, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import copy from 'copy-to-clipboard';
import { useClickAway } from 'react-use';

import { useDispatch } from 'react-redux';
import { toggle_modal } from 'redux/actions/modal';

import ButtonClose from 'components/ButtonClose';

import { timeout } from 'helper';
import { trackEventGA } from 'helper/default';

const Share = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShareFb = () => {
    const appId = 480876283844204;
    const uri_fb = encodeURIComponent(shareUrl);
    const redirectURI = encodeURIComponent(origin);
    const shareURI = `https://www.facebook.com/dialog/share?app_id=${appId}&display=page&href=${uri_fb}&redirect_uri=${redirectURI}`;
    window.location.href = shareURI
  };

  const ref = useRef(null);
  const dispatch = useDispatch();
  const { share } = props.modal;
  const { tr } = props.main;
  const { origin } = window.location;

  const shareUrl = origin;
  const copyUrl = origin;

  useClickAway(ref, () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  });

  useEffect(() => {
    if (showTooltip) {
      timeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  }, [showTooltip]);

  useEffect(() => {
    if (share) {
      setShowTooltip(false);
    }
  }, [share]);

  return (
    <Modal
      open={share}
      onClose={() => dispatch(toggle_modal('share', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: `modal share animated fast ${
          share ? 'slideInUp' : 'slideOutDown'
        }`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'slideOutDown',
      }}
    >
      <div className="title center">
        {tr.txt_share}
        <ButtonClose type="share" />
      </div>
      <div className="content">
        <div className="icon-dstrb"></div>
        <button 
          className="btn-fb"
          onClick={() => {
            trackEventGA("Share", "Click Share FB", "Share")
            handleShareFb()
          }
        }>
          <div className="icon" /> <span>{tr.txt_share_fb}</span>
        </button>
        <button
          ref={ref}
          id="btn-copy"
          className="btn-copy"
          onClick={() => {
            trackEventGA("Share", "Click Copy Link", "Share")
            copy(copyUrl);
            setShowTooltip(true);
          }}
        >
          <div className="icon" /> <span>{tr.txt_copy_link}</span>
        </button>
        {showTooltip && <div className="tooltip">{tr.txt_link_copied}</div>}
      </div>
    </Modal>
  );
};

export default Share;
