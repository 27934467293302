const main_state = {
  login: false,
  loader: false,
  lang: false,
  tr: false,
  mute: false,
  from_outgame: false,
  show_tutorial: true,
  invite_code: false,
  is_already_register: false,
  weapon: 1,
  user: false,
  local_score: 0,
  initial_score: 0,
  unsended_score: 0,
  rewards: [],
  servers: [],
  scores: false,
  leaderboards: false,
  accept_policy: false
};

const main = (state = main_state, action) => {
  switch (action.type) {
    case 'PUT_DATA':
      return { ...state, [action.key]: action.data };
    case 'UPDATE_LEADERBOARD':
      // only 5 regions
      state.leaderboards.leaderboard?.forEach((data, idx) => {
        if (data.region_key === action.region) {
          state.leaderboards.leaderboard[idx] = {region_key: action.region, score: action.score, region_name: data.region_name};
          state.leaderboards.leaderboard.sort((a, b) => b.score - a.score);
        }
      });
      return { ...state };
    case 'TOGGLE_LOADER':
      return { ...state, loader: action.data };
    default:
      return state;
  }
};

export default main;
